@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    color-scheme: dark;
  }

  body,
  html {
    @apply font-sans;
    font-feature-settings: "pnum", "lnum";
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    @apply relative min-h-full m-0 bg-black text-white break-words;
    max-width: 100vw;
  }

  p {
    @apply leading-7 text-slate-400;
  }
}

/* Adding CSS manually is only necessary in JSFiddle,
   where CSS can't be inserted into the <head> */
.typed-cursor{
  opacity: 1;
  animation: typedjsBlink 0.7s infinite;
  -webkit-animation: typedjsBlink 0.7s infinite;
  animation: typedjsBlink 0.7s infinite;
}
@keyframes typedjsBlink{
  50% { opacity: 0.0; }
}
@-webkit-keyframes typedjsBlink{
  0% { opacity: 1; }
  50% { opacity: 0.0; }
  100% { opacity: 1; }
}
.typed-fade-out{
  opacity: 0;
  transition: opacity .25s;
  -webkit-animation: 0;
  animation: 0;
}

#logo {
  font-family: 'Kaushan Script', cursive;
  
}